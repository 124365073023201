import {
  combineReducers,
  configureStore,
  PreloadedState,
  SerializedError,
} from '@reduxjs/toolkit';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import userReducer from './userSlice';
import { presentationApi } from './presentationApi';
import { bondsApi } from './bondsApi';
import { partyApi } from './partyApi';
import { transactionApi } from './transactionApi';
import { analyticalStructureApi } from './analyticalStructureApi';
import { portfolioApi } from './portfolioApi';
import { genericStaticDataApi } from './genericStaticDataApi';
import { extractApi } from './extractApi';
import { marketRiskApi } from './marketRiskApi';

const appReducer = combineReducers({
  user: userReducer,
  [presentationApi.reducerPath]: presentationApi.reducer,
  [bondsApi.reducerPath]: bondsApi.reducer,
  [partyApi.reducerPath]: partyApi.reducer,
  [transactionApi.reducerPath]: transactionApi.reducer,
  [analyticalStructureApi.reducerPath]: analyticalStructureApi.reducer,
  [portfolioApi.reducerPath]: portfolioApi.reducer,
  [genericStaticDataApi.reducerPath]: genericStaticDataApi.reducer,
  [extractApi.reducerPath]: extractApi.reducer,
  [marketRiskApi.reducerPath]: marketRiskApi.reducer,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setupStore = (preloadedState?: PreloadedState<AppState>) =>
  configureStore({
    reducer: appReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['user/setSgConnect'],
          ignoredPaths: [
            'user.globalContext.setClientScope',
            'user.globalContext.sgConnect',
          ],
        },
      })
        .concat(presentationApi.middleware)
        .concat(bondsApi.middleware)
        .concat(partyApi.middleware)
        .concat(transactionApi.middleware)
        .concat(analyticalStructureApi.middleware)
        .concat(portfolioApi.middleware)
        .concat(genericStaticDataApi.middleware)
        .concat(extractApi.middleware)
        .concat(marketRiskApi.middleware),
  });

export function getErrorMessage(
  error: FetchBaseQueryError | SerializedError,
): string {
  let errMsg: string;
  if ('status' in error) {
    errMsg =
      typeof error.data === 'string'
        ? error.data
        : JSON.stringify(error.data) ??
          ('error' in error ? error.error : 'Unexpected error');
  } else {
    errMsg = error.message ?? 'Unexpected error';
  }
  return errMsg;
}

export type AppState = ReturnType<typeof appReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export const store = setupStore();
