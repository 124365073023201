import { getConfig } from '@/config/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { commonPrepareHeaders } from './apiUtils';

export const marketRiskApi = createApi({
  reducerPath: 'marketRiskApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${getConfig()?.api?.endpoint ?? ''}market-risk/`,
    prepareHeaders: commonPrepareHeaders,
  }),
  tagTypes: ['MarketRisk'],
  endpoints: (builder) => ({
    getTradeInformation: builder.query<
    TradeInformationForMarketRisk,
      {contractId:string, asOf:string}
    >({
      query: ({contractId, asOf}) => ({
        url: 'trade-information',
        method: 'GET',
        params: [['contractId', contractId], ['asOf', asOf]],
      }),
      keepUnusedDataFor: 60 * 10,
    })
  })
});

export const {
  useLazyGetTradeInformationQuery
} = marketRiskApi;