import React, { useEffect, useMemo } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import {
  useGetClientScopeQuery,
  useGetClientScopesQuery,
  useSetClientScopeMutation,
} from '@/store/presentationApi';
import { Sgb4Spinner } from './Sgb4Spinner';
import { ToasterContext } from '../../context/ToasterContext';
import { LoadingRenderComponent } from './LoadingRenderComponent';

export const ClientScopeSelector: React.FunctionComponent = () => {
  const [chooseClientScope, chooseStatus] = useSetClientScopeMutation();

  const clientScopeCode = useGetClientScopeQuery();
  const clientScopes = useGetClientScopesQuery();

  const { pushToastMessage } = React.useContext(ToasterContext);
  const [showClientScopesDropDown, setShowClientScopesDropDown] =
    React.useState(false);
  const displayDropDown = useMemo(
    () =>
      clientScopes.isSuccess &&
      clientScopeCode.isSuccess &&
      clientScopes.data.length > 1 &&
      showClientScopesDropDown,
    [clientScopes, clientScopeCode, showClientScopesDropDown],
  );

  useEffect(() => {
    if (clientScopes.isError) {
      pushToastMessage({
        body: { id: 'header.error-fetching-client-scopes' },
        level: 'danger',
        title: { id: 'label.danger' },
      });
    }
  }, [clientScopes]);

  const toggleShowClientScopes = () => {
    setShowClientScopesDropDown(!showClientScopesDropDown);
  };

  useEffect(() => {
    if (chooseStatus.isSuccess && !chooseStatus.isLoading) {
      console.info('Client-scope changed!');
    } else if (chooseStatus.isError) {
      console.error('Failed to change client scope: ', chooseStatus.error);
      pushToastMessage({
        body: { id: 'header.error-setting-client-scopes' },
        level: 'danger',
        title: { id: 'label.danger' },
      });
    }
  }, [chooseStatus]);

  useEffect(() => {
    if (clientScopeCode.isSuccess && !clientScopeCode.isFetching) {
      console.info('Client-scope selected :', clientScopeCode.data);
    }
  }, [clientScopeCode]);

  const currentClientScopeLabel = useMemo(() => {
    if (
      !clientScopes.isSuccess ||
      !clientScopes.data ||
      !clientScopeCode.isSuccess ||
      !clientScopeCode.data
    ) {
      return '-';
    }

    return (
      clientScopes.data.find(
        (clientScope) => clientScope.code === clientScopeCode.data,
      )?.label ?? '-'
    );
  }, [clientScopes, clientScopeCode]);

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={displayDropDown}
      toggle={toggleShowClientScopes}
      style={{ listStyleType: 'none' }}
    >
      <DropdownToggle nav caret={false}>
        Scope: {currentClientScopeLabel}
      </DropdownToggle>
      <DropdownMenu left="true">
        <LoadingRenderComponent
          isLoading={clientScopes.isLoading}
          loadingComponent={
            <DropdownItem>
              <Sgb4Spinner size="xs" />
              Loading your client-scopes...
            </DropdownItem>
          }
        >
          {clientScopes.isSuccess &&
            clientScopeCode.isSuccess &&
            clientScopes.data.length > 1 &&
            clientScopes.data.map((clientScope) => (
              <React.Fragment key={clientScope.code}>
                <DropdownItem
                  className={
                    clientScopeCode.data === clientScope.code ? 'active' : ''
                  }
                  onClick={() => chooseClientScope(clientScope.code)}
                >
                  {clientScope.label}
                </DropdownItem>
                <DropdownItem divider />
              </React.Fragment>
            ))}
        </LoadingRenderComponent>
      </DropdownMenu>
    </Dropdown>
  );
};
