import React from 'react';

interface ConfirmationModalContextProps {
  askForConfirmation: (
    intlMessage: FormattedMessageProps,
    onConfirm: () => void,
  ) => void;
}

export const ConfirmationModalContext =
  React.createContext<ConfirmationModalContextProps>({
    askForConfirmation: () => undefined,
  });
