import {
  createEntityAdapter,
  createSelector,
  EntityState,
} from '@reduxjs/toolkit';
import { DataContractProperty } from '@sgmo/shared-components';
import { presentationApi } from './presentationApi';
import { AppState } from './store';

const screensAdapter = createEntityAdapter<StaticDataScreens>({
  selectId: (screen: StaticDataScreens) => screen.title,
});

const screensInitialState = screensAdapter.getInitialState();

const navigateAsScreensAdapter = createEntityAdapter<StaticDataScreens>({
  selectId: (screen: StaticDataScreens) => screen.title,
});

const navigateAsScreensInitialState =
  navigateAsScreensAdapter.getInitialState();

export const staticDataApiSlice = presentationApi.injectEndpoints({
  endpoints: (builder) => ({
    getStaticDataScreens: builder.query<EntityState<StaticDataScreens>, void>({
      query: () => ({
        url: 'static-datas',
        method: 'GET',
      }),
      transformResponse(baseQueryReturnValue: StaticDataScreens, _meta, _arg) {
        return screensAdapter.upsertMany(
          screensInitialState,
          baseQueryReturnValue.menus,
        );
      },
      providesTags: ['StaticDataScreens'],
      keepUnusedDataFor: 60 * 60 * 24 * 365,
    }),
    getStaticDataScreensForUser: builder.query<
      EntityState<StaticDataScreens>,
      string
    >({
      query: (userName: string) => ({
        url: `static-datas/${userName}`,
        method: 'GET',
      }),
      transformResponse(baseQueryReturnValue: StaticDataScreens, _meta, _arg) {
        return navigateAsScreensAdapter.setMany(
          navigateAsScreensInitialState,
          baseQueryReturnValue.menus,
        );
      },
      providesTags: ['StaticDataScreens'],
      keepUnusedDataFor: 60 * 60 * 24 * 365,
    }),
    getStaticDataContract: builder.query<DataContractProperty, string>({
      query: (screenName) => ({
        url: `static-datas/${screenName}/contract`,
        method: 'GET',
      }),
      providesTags: (_result, _error, arg) => [
        { type: 'ScreenContracts', id: arg },
      ],
      keepUnusedDataFor: 60 * 60 * 24 * 365,
    }),
    searchAllStaticDatas: builder.query<
      StaticDataSearchResults,
      { search: string; maxNumberOfResults?: number }
    >({
      query: ({ search, maxNumberOfResults }) => ({
        url: 'static-datas/search',
        method: 'GET',
        params: [
          ['s', search],
          [
            'm',
            maxNumberOfResults !== undefined
              ? maxNumberOfResults.toString()
              : '10',
          ],
        ],
      }),
      keepUnusedDataFor: 60 * 60,
    }),
  }),
});

export const {
  useGetStaticDataScreensQuery,
  useLazyGetStaticDataScreensForUserQuery,
  useGetStaticDataContractQuery,
  useLazySearchAllStaticDatasQuery,
} = staticDataApiSlice;

const selectStaticDataScreensResult =
  staticDataApiSlice.endpoints.getStaticDataScreens.select();

const selectStaticDataScreensData = createSelector(
  selectStaticDataScreensResult,
  (result) => result.data,
);

export const { selectAll: selectAllStaticDataScreens } =
  screensAdapter.getSelectors(
    (state: AppState) =>
      selectStaticDataScreensData(state) ?? screensInitialState,
  );

const selectNavigateAsStaticDataScreensData = createSelector(
  (state: AppState) =>
    staticDataApiSlice.endpoints.getStaticDataScreensForUser.select(
      state.user.navigateAsUser?.name ?? '',
    )(state),
  (result) => result.data,
);

export const { selectAll: selectAllStaticDataScreensForUser } =
  navigateAsScreensAdapter.getSelectors(
    (state: AppState) =>
      selectNavigateAsStaticDataScreensData(state) ??
      navigateAsScreensInitialState,
  );

export const selectVisibleStaticDataScreens = createSelector(
  (state: AppState) =>
    state.user.navigateAsUser
      ? selectAllStaticDataScreensForUser(state)
      : selectAllStaticDataScreens(state),
  (screens) => screens,
);
