import { getConfig } from '@/config/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { commonPrepareHeaders } from './apiUtils';

export const bondsApi = createApi({
  reducerPath: 'bondsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${getConfig()?.api?.endpoint ?? ''}bonds/`,
    prepareHeaders: commonPrepareHeaders,
  }),
  tagTypes: ['Bonds'],
  endpoints: (builder) => ({
    searchBonds: builder.query<ShortBondModel[], string>({
      query: (like) => ({
        url: '',
        method: 'GET',
        params: [['like', like]],
      }),
      transformResponse: (
        baseQueryReturnValue: { bonds: ShortBondModel[] },
        _meta,
        _arg,
      ) => baseQueryReturnValue.bonds,
    }),
    getBond: builder.query<BondModel, number>({
      query: (bdrCode) => ({
        url: `${bdrCode}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, arg) => [{ type: 'Bonds', id: arg }],
      keepUnusedDataFor: 60 * 60 * 24 * 365,
    }),
  }),
});

export const { useLazySearchBondsQuery, useGetBondQuery } = bondsApi;
