import { getConfig } from '@/config/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { analyticalStructureApi } from './analyticalStructureApi';
import { commonPrepareHeaders } from './apiUtils';

export const portfolioApi = createApi({
  reducerPath: 'portfolioApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${getConfig()?.api?.endpoint ?? ''}portfolios/`,
    prepareHeaders: commonPrepareHeaders,
  }),
  tagTypes: ['Portfolio'],
  endpoints: (builder) => ({
    getPortfolio: builder.query<
      Record<string, string | boolean | number | null>,
      string
    >({
      query: (mnemonic) => ({
        url: `${mnemonic}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 60 * 60 * 24 * 365,
      providesTags: (_result, _error, mnemonic) => [
        { type: 'Portfolio', mnemonic },
      ],
    }),
    togglePortfolioBookmark: builder.mutation<
      void,
      { mnemonic: string; isBookmarked: boolean; identifier: string }
    >({
      query: ({ mnemonic, isBookmarked }) => ({
        url: `bookmarks/${mnemonic}`,
        method: isBookmarked ? 'DELETE' : 'POST',
      }),
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          // Ugly fix because we can't parse the whole cache and just update our
          // node every time we encounter it
          dispatch(
            analyticalStructureApi.util.invalidateTags([
              'AnalyticalStructureNode',
            ]),
          );
          // eslint-disable-next-line no-empty
        } catch {}
      },
    }),
  }),
});

export const { useLazyGetPortfolioQuery, useTogglePortfolioBookmarkMutation } =
  portfolioApi;
