import { getConfig } from '@/config/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { JsonObj } from '@sgmo/shared-components';
import { commonPrepareHeaders } from './apiUtils';

export const genericStaticDataApi = createApi({
  reducerPath: 'genericStaticDataApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${getConfig()?.api?.endpoint ?? ''}`,
    prepareHeaders: commonPrepareHeaders,
  }),
  endpoints: (builder) => ({
    /**
     * Handles all generic static-data requests not providing all values of a given static-data. The key parameter here will be happened to the URL,
     * meaning that it can be a single key or something more complex.
     * @param scopeName Scope of static-data, eg: 'controls-data'.
     * @param staticDataName Name of the static-data, eg: 'alm-treasury-rate-thresholds'.
     * @param key Key of the perimeter of static-data we want. eg: '2021-09-23'.
     * @param abortSignal
     * @param globalContext
     * @returns
     */
    getSome: builder.query<
      JsonObj,
      {
        scopeName: string;
        staticDataName: string;
        key: string;
        params: [string, string][];
      }
    >({
      query: ({ scopeName, staticDataName, key, params }) => ({
        url: `${scopeName}/${staticDataName}/${key}`,
        method: 'GET',
        params,
      }),
      keepUnusedDataFor: 0.0001,
    }),
    /**
     * Handles generic static-data requests providing all values of a given static-data.
     * @param scopeName Scope of static-data, eg: 'controls-data'.
     * @param staticDataName Name of the static-data, eg: 'alm-treasury-rate-thresholds'.
     * @param abortSignal
     * @param globalContext
     * @returns
     */
    getAll: builder.query<
      JsonObj,
      { scopeName: string; staticDataName: string }
    >({
      query: ({ scopeName, staticDataName }) => ({
        url: `${scopeName}/${staticDataName}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyGetSomeQuery } = genericStaticDataApi;
