export const ApplicationRoutes: Record<string, NavigationRouteDetail> = {
  party: {
    title: {
      id: 'menu.crm',
    },
    body: {
      id: 'home.crm-summary',
    },
    navigateTo: {
      pathname: `/crm/`,
      state: { shouldResetScroll: true },
    },
    icon: 'groups',
    accessName: 'TOne.StaticData',
    isPermission: false,
  },
  bond: {
    title: {
      id: 'menu.bond',
    },
    body: {
      id: 'home.bond-summary',
    },
    navigateTo: {
      pathname: `/bond/`,
      state: { shouldResetScroll: true },
    },
    icon: 'groups',
    accessName: 'CanManagePrivateBonds',
    isPermission: true,
  },
  'analytical structure': {
    title: {
      id: 'menu.analytical-structure',
    },
    body: {
      id: 'home.analytical-structure-summary',
    },
    navigateTo: {
      pathname: `/analytical-structure/`,
      state: { shouldResetScroll: true },
    },
    icon: 'account_tree',
    accessName: 'TOne.AnalyticalStructure',
    isPermission: false,
  },
  'market risk': {
    title: {
      id: 'menu.market-risk',
    },
    body: {
      id: 'home.market-risk-summary',
    },
    navigateTo: {
      pathname: `/market-risk/`,
      state: { shouldResetScroll: true },
    },
    icon: 'account_tree',
    accessName: 'TOne.MarketRisk',
    isPermission: false,
  },
  extraction: {
    title: {
      id: 'menu.extraction',
    },
    body: {
      id: 'home.extraction-summary',
    },
    navigateTo: {
      pathname: '/extraction/',
      state: { shouldResetScroll: true },
    },
    icon: 'download',
    accessName: 'TOne.StaticData',
    isPermission: false,
  },
};
