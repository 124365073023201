import { getConfig } from '@/config/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { commonPrepareHeaders } from './apiUtils';

export const extractApi = createApi({
  reducerPath: 'extractApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${getConfig()?.api?.endpoint ?? ''}/extracts`,
    prepareHeaders: commonPrepareHeaders,
  }),
  endpoints: (builder) => ({
    extractPartiesCrm: builder.mutation<Blob, void>({
      query: () => ({ url: 'parties-crm', method: 'GET' }),
    }),
  }),
});

export const { useExtractPartiesCrmMutation } = extractApi;
