import React, { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import {
  addUserConsentSettingsCallback,
  getUserConsentOnMatomo,
} from '@/helpers/cookieConsent';
import { useAppSelector } from '@/store/store';
import { selectContext } from '@/store/userSlice';

export function PageTracker({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const { trackPageView, pushInstruction } = useMatomo();
  const location = useLocation();
  //  const userInfo = useGetUserInfoQuery();
  const { locale } = useIntl();

  const context = useAppSelector(selectContext);
  const sgConnect = context?.sgConnect;

  useEffect(() => {
    pushInstruction('setUserId', sgConnect?.getIdTokenClaims()?.sub);
    pushInstruction('setCustomVariable', 1, 'locale', locale, 'visit');
    trackPageView({});
  }, [pushInstruction, locale, trackPageView]);

  // Every time the location changes, we track the page view on Matomo.
  useEffect(() => {
    function updateConsent() {
      getUserConsentOnMatomo().then((consent) => {
        // Documentation: https://developer.sgmarkets.com/docs/web/widgets/structural-widgets/advanced-usage/cookies-consent.html
        if (consent) {
          pushInstruction('rememberCookieConsentGiven', 4320);
          pushInstruction('setCustomDimension', 10, true);
        } else {
          pushInstruction('forgetCookieConsentGiven');
          pushInstruction('setCustomDimension', 10, false);
        }
      });
    }

    pushInstruction('requireCookieConsent');
    updateConsent();
    trackPageView({});
    addUserConsentSettingsCallback(updateConsent);

    // Only care about change location
  }, [location]);

  return <>{children}</>;
}
