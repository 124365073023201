import { getConfig } from '@/config/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { commonPrepareHeaders } from './apiUtils';

export const analyticalStructureApi = createApi({
  reducerPath: 'analyticalStructureApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${getConfig()?.api?.endpoint ?? ''}analytical-structure/`,
    prepareHeaders: commonPrepareHeaders,
  }),
  tagTypes: ['AnalyticalStructureNode'],
  endpoints: (builder) => ({
    searchAnalyticalStructureNodes: builder.query<
      AnalyticalStructureNodeModel[],
      string
    >({
      query: (search) => ({
        url: 'nodes/search',
        method: 'GET',
        params: [['s', search]],
      }),
      transformResponse: (
        baseQueryReturnValue: { nodes: AnalyticalStructureNodeModel[] },
        _meta,
        _arg,
      ) => baseQueryReturnValue.nodes,
      keepUnusedDataFor: 60 * 10,
    }),
    getAnalyticalStructureNode: builder.query<
      AnalyticalStructureNodeModel,
      { nodeIdentifier: string; full: boolean; withAscendants: boolean }
    >({
      query: ({ nodeIdentifier, full, withAscendants }) => ({
        url: `nodes/${nodeIdentifier}`,
        method: 'GET',
        params: [
          ['full', full ? 'true' : 'false'],
          ['withAscendants', withAscendants ? 'true' : 'false'],
        ],
      }),
      providesTags: ['AnalyticalStructureNode'],
    }),
  }),
});

export const {
  useLazySearchAnalyticalStructureNodesQuery,
  useLazyGetAnalyticalStructureNodeQuery,
} = analyticalStructureApi;
