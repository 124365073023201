import { getConfig } from '@/config/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { NavigateAsUser } from '@sgmo/shared-components';
import { createSelector } from '@reduxjs/toolkit';
import { commonPrepareHeaders } from './apiUtils';

export const presentationApi = createApi({
  reducerPath: 'presentationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${getConfig()?.api?.endpoint ?? ''}presentation/`,
    prepareHeaders: commonPrepareHeaders,
  }),
  tagTypes: [
    'ClientScope',
    'Permissions',
    'ScreenContracts',
    'NavigateAsUsers',
    'StaticDataScreens',
  ],
  endpoints: (builder) => ({
    getClientScopes: builder.query<XOneClientScope[], void>({
      query: () => ({
        url: 'client-scopes',
        method: 'GET',
        headers: [['no-client-scope', 'true']],
      }),
      transformResponse: (
        baseQueryReturnValue: { clientScopes: XOneClientScope[] },
        _meta,
        _arg,
      ) => baseQueryReturnValue.clientScopes,
      keepUnusedDataFor: 60 * 60 * 24 * 365,
    }),
    getNavigateAsUsers: builder.query<NavigateAsUser[], void>({
      query: () => ({
        url: 'navigate-as-users',
        method: 'GET',
        headers: [['no-client-scope', 'true']],
      }),
      transformResponse: (
        baseQueryReturnValue: { navigateAsUsers: NavigateAsUser[] },
        _meta,
        _arg,
      ) => baseQueryReturnValue.navigateAsUsers,
      providesTags: ['NavigateAsUsers'],
      keepUnusedDataFor: 60 * 60 * 24 * 365,
    }),
    getClientScope: builder.query<string, void>({
      query: () => ({
        url: 'preferences/client-scope',
        method: 'GET',
        headers: [['no-client-scope', 'true']],
      }),
      transformResponse: (
        baseQueryReturnValue: { code: string },
        _meta,
        _arg,
      ) => baseQueryReturnValue.code,
      providesTags: ['ClientScope'],
      keepUnusedDataFor: 60 * 60 * 24 * 365,
    }),
    setClientScope: builder.mutation<void, string>({
      query: (newClientScopeCode) => ({
        url: 'preferences/client-scope',
        method: 'PUT',
        params: [['clientScope', newClientScopeCode]],
        headers: [['no-client-scope', 'true']],
      }),
      invalidatesTags: [
        'ClientScope',
        'NavigateAsUsers',
        'Permissions',
        'StaticDataScreens',
        'ScreenContracts',
      ],
    }),
  }),
});

export const {
  useGetClientScopesQuery,
  useGetClientScopeQuery,
  useSetClientScopeMutation,
  useGetNavigateAsUsersQuery,
} = presentationApi;

export const selectNavigateAsUsersResult =
  presentationApi.endpoints.getNavigateAsUsers.select();

export const selectNavigateAsUsers = createSelector(
  selectNavigateAsUsersResult,
  (result) => result.data ?? [],
);
