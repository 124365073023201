import React, { useState } from 'react';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ToasterContext } from '../../context/ToasterContext';

interface TimestampedToastMessage extends ToastMessage {
  timestamp: number;
  isOpen: boolean;
}

const ToasterContainer = styled.aside.attrs({
  className: 'p-3',
})`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 5000;
`;

export const ToasterProvider: React.FC = ({ children }: OnlyChildrenProps) => {
  const [toastMessages, setToastMessages] = useState<TimestampedToastMessage[]>(
    [],
  );

  const pushToast = (toastMessage: ToastMessage) => {
    const tToastMessage = {
      ...toastMessage,
      timestamp: Date.now(),
      isOpen: true,
    };
    const newList = [...toastMessages, tToastMessage];
    setToastMessages(newList);
    setTimeout(() => {
      setToastMessages((prevList) =>
        prevList
          .filter((toast) => toast.isOpen) // toast deletion in two steps so it renders nicely the fade out and it cleans the list
          .map((toast) => {
            if (
              !toast.timestamp ||
              toast.timestamp === tToastMessage.timestamp
            ) {
              return { ...toast, isOpen: false };
            }
            return toast;
          }),
      );
    }, 5000);
  };

  return (
    <ToasterContext.Provider value={{ pushToastMessage: pushToast }}>
      {children}
      <ToasterContainer>
        {toastMessages.map((t) => (
          <Toast
            key={t.timestamp}
            className={`toast-${t.level}`}
            isOpen={t.isOpen}
          >
            <ToastHeader>
              <FormattedMessage id={t.title.id} values={t.title.values} />
            </ToastHeader>
            <ToastBody>
              <FormattedMessage id={t.body.id} values={t.body.values} />
            </ToastBody>
          </Toast>
        ))}
      </ToasterContainer>
    </ToasterContext.Provider>
  );
};
