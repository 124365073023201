import React, { Fragment, useEffect, useState } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { BUS_TOPIC_GLOBALLANGUAGE, getWidgetBus } from '@sgmo/shared';
import en from '../locales/en.json';
import fr from '../locales/fr.json';
import ru from '../locales/ru.json';

type SupportedLocale = 'en' | 'fr' | 'ru';
export const locales: {
  [key in SupportedLocale]: { [key: string]: string };
} = {
  en,
  fr,
  ru,
};

export const IntlProvider: React.FC = ({ children }: OnlyChildrenProps) => {
  const [locale, setLocale] = useState<SupportedLocale>('en');

  // Use the SGWT Widgets bus to be aware of the modification of the language,
  // which is triggered by the <sgwt-account-center> widget.
  useEffect(() => {
    const bus = getWidgetBus();

    if (bus) {
      const languageSubscription = bus.subscribe<string>(
        BUS_TOPIC_GLOBALLANGUAGE,
        (lang) => {
          if (lang && Object.keys(locales).includes(lang.toLowerCase())) {
            setLocale(lang.toLowerCase() as SupportedLocale);
          }
        },
      );
      return () => {
        bus.unsubscribe(languageSubscription);
      };
    }
    return undefined;
  }, []);

  return (
    <ReactIntlProvider
      locale={locale}
      messages={locales[locale]}
      textComponent={Fragment}
    >
      {children}
    </ReactIntlProvider>
  );
};
