import { getConfig } from '@/config/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { commonPrepareHeaders } from './apiUtils';

export const partyApi = createApi({
  reducerPath: 'partyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${getConfig()?.api?.endpoint ?? ''}parties/`,
    prepareHeaders: commonPrepareHeaders,
  }),
  tagTypes: ['BookmarkedParties', 'Party', 'PartyCrm'],
  endpoints: (builder) => ({
    // Parties
    searchParties: builder.query<ShortPartyModel[], string>({
      query: (like) => ({
        url: '',
        method: 'GET',
        params: [['like', like]],
      }),
      transformResponse: (
        baseQueryReturnValue: { parties: ShortPartyModel[] },
        _meta,
        _arg,
      ) => baseQueryReturnValue.parties,
      // Keep result for 10 minutes, cache value will only be used when using the "back" button from detail
      keepUnusedDataFor: 60 * 10,
    }),
    getParty: builder.query<PartyModel, string>({
      query: (mnemonic) => ({
        url: `${mnemonic}`,
        method: 'GET',
      }),
      // Party should rarely be updated, keep it for the whole lifetime
      keepUnusedDataFor: 60 * 60 * 24 * 365,
      providesTags: (_result, _error, mnemonic) => [
        { type: 'Party', mnemonic },
      ],
    }),
    // PartyCRM
    getPartyCrm: builder.query<PartyCrmModel, string>({
      query: (mnemonic) => ({
        url: `crm/${mnemonic}`,
        method: 'GET',
      }),
      // Keep result for 10 minutes
      keepUnusedDataFor: 60 * 10,
      providesTags: (_result, _error, mnemonic) => [
        { type: 'PartyCrm', mnemonic },
      ],
    }),
    getCrmAddressEnums: builder.query<CrmAddressEnums, void>({
      query: () => ({
        url: 'labels/crm/addresses',
        method: 'GET',
      }),
      keepUnusedDataFor: 60 * 60 * 24 * 365,
    }),
    getCrmAddresses: builder.query<CrmAddresses, string>({
      query: (mnemonic) => ({
        url: `crm/${mnemonic}/addresses`,
        method: 'GET',
      }),
      keepUnusedDataFor: 60 * 10,
    }),
    updatePartyCrm: builder.mutation<PartyCrmModel, PartyCrmModel>({
      query: (partyCrm) => ({
        url: 'crm',
        method: 'PUT',
        body: partyCrm,
      }),
      async onQueryStarted({ mnemonic }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPartyCrm } = await queryFulfilled;
          dispatch(
            partyApi.util.updateQueryData('getPartyCrm', mnemonic, (draft) =>
              Object.assign(draft, updatedPartyCrm),
            ),
          );
          // eslint-disable-next-line no-empty
        } catch {}
      },
    }),
    createCrmAddress: builder.mutation<
      CrmAddresses,
      { mnemonic: string; newAddress: EditionCrmAddress }
    >({
      query: ({ mnemonic, newAddress }) => ({
        url: `crm/${mnemonic}/addresses`,
        method: 'POST',
        body: newAddress,
      }),
      async onQueryStarted(
        { mnemonic, newAddress },
        { dispatch, queryFulfilled },
      ) {
        try {
          const { data: listOfAddressesWithSameType } = await queryFulfilled;
          dispatch(
            partyApi.util.updateQueryData(
              'getCrmAddresses',
              mnemonic,
              (draft) => {
                const newList = draft.counterpartyAddresses
                  .filter((item) => item.addressType !== newAddress.addressType)
                  .concat(listOfAddressesWithSameType.counterpartyAddresses);
                return {
                  counterpartyMnemonic: draft.counterpartyMnemonic,
                  counterpartyAddresses: newList,
                };
              },
            ),
          );
          // eslint-disable-next-line no-empty
        } catch {}
      },
    }),
    updateCrmAddress: builder.mutation<
      CrmAddresses,
      { mnemonic: string; address: EditionCrmAddress }
    >({
      query: ({ mnemonic, address }) => ({
        url: `crm/${mnemonic}/addresses`,
        method: 'PUT',
        body: address,
      }),
      async onQueryStarted(
        { mnemonic, address },
        { dispatch, queryFulfilled },
      ) {
        try {
          const { data: listOfAddressesWithSameType } = await queryFulfilled;
          dispatch(
            partyApi.util.updateQueryData(
              'getCrmAddresses',
              mnemonic,
              (draft) => {
                const newList = draft.counterpartyAddresses
                  .filter((item) => item.addressType !== address.addressType)
                  .concat(listOfAddressesWithSameType.counterpartyAddresses);
                return {
                  counterpartyMnemonic: draft.counterpartyMnemonic,
                  counterpartyAddresses: newList,
                };
              },
            ),
          );
          // eslint-disable-next-line no-empty
        } catch {}
      },
    }),
    deleteCrmAddress: builder.mutation<
      CrmAddresses,
      {
        mnemonic: string;
        addressType: string;
        bdrFamilyProduct: number;
        bdrSubFamilyProduct: number;
      }
    >({
      query: ({
        mnemonic,
        addressType,
        bdrFamilyProduct,
        bdrSubFamilyProduct,
      }) => ({
        url: `crm/${mnemonic}/addresses/${addressType}`,
        method: 'DELETE',
        param: [
          ['bdrFamily', `${bdrFamilyProduct}`],
          ['bdrSubFamily', `${bdrSubFamilyProduct}`],
        ],
      }),
      async onQueryStarted(
        { mnemonic, addressType },
        { dispatch, queryFulfilled },
      ) {
        try {
          const { data: listOfAddressesWithSameType } = await queryFulfilled;
          dispatch(
            partyApi.util.updateQueryData(
              'getCrmAddresses',
              mnemonic,
              (draft) => {
                const newList = draft.counterpartyAddresses
                  .filter((item) => item.addressType !== addressType)
                  .concat(listOfAddressesWithSameType.counterpartyAddresses);
                return {
                  counterpartyMnemonic: draft.counterpartyMnemonic,
                  counterpartyAddresses: newList,
                };
              },
            ),
          );
          // eslint-disable-next-line no-empty
        } catch {}
      },
    }),
    // Party Bookmarks
    getBookmarkedParties: builder.query<ShortPartyModel[], void>({
      query: () => ({
        url: 'bookmarks',
        method: 'GET',
      }),
      transformResponse: (
        baseQueryReturnValue: { parties: ShortPartyModel[] },
        _meta,
        _arg,
      ) => baseQueryReturnValue.parties,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ mnemonic }) => ({
                type: 'BookmarkedParties' as const,
                mnemonic,
              })),
              { type: 'BookmarkedParties', mnemonic: 'LIST' },
            ]
          : [{ type: 'BookmarkedParties', mnemonic: 'LIST' }],
    }),
    toggleBookmark: builder.mutation<void, ShortPartyModel>({
      query: ({ mnemonic, isBookmarked }) => ({
        url: `bookmarks/${mnemonic}`,
        method: isBookmarked ? 'DELETE' : 'POST',
      }),
      onQueryStarted(crmDefinition, { dispatch, queryFulfilled }) {
        const patchBookmarksResult = dispatch(
          partyApi.util.updateQueryData(
            'getBookmarkedParties',
            undefined,
            (parties) => {
              if (crmDefinition.isBookmarked) {
                const index = parties.findIndex(
                  (p) => p.mnemonic === crmDefinition.mnemonic,
                );
                if (index > -1) {
                  parties.splice(index, 1);
                }
              } else {
                parties.push({ ...crmDefinition, isBookmarked: true });
              }
            },
          ),
        );

        queryFulfilled.catch(() => {
          patchBookmarksResult.undo();
        });
      },
    }),
    // Analytical Structure
    getLabels: builder.query<EnumerationsWithLabels, void>({
      query: () => ({
        url: 'labels',
        method: 'GET',
      }),
      keepUnusedDataFor: 60 * 60 * 24 * 365,
    }),
  }),
});

export const {
  useLazySearchPartiesQuery,
  useGetPartyQuery,
  useLazyGetPartyCrmQuery,
  useLazyGetCrmAddressEnumsQuery,
  useUpdatePartyCrmMutation,
  useGetCrmAddressesQuery,
  useCreateCrmAddressMutation,
  useUpdateCrmAddressMutation,
  useDeleteCrmAddressMutation,
  useGetBookmarkedPartiesQuery,
  useToggleBookmarkMutation,
  useGetLabelsQuery,
} = partyApi;
