import { getConfig } from '@/config/config';
import React from 'react';

export function HelpCenter(): JSX.Element {
  return (
    <aside>
      <sgwt-help-center
        application-id={(import.meta as any).env.VITE_ID as string}
        default-send-to={getConfig().helpCenter.contact}
        mail-subject={getConfig().helpCenter.subject}
        sg-connect-support="sg-connect-v2"
      />
    </aside>
  );
}
