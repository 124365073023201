import React, { useEffect, useRef } from 'react';
import type { SgwtSplashScreenHTMLElement } from '@sgmo/shared';

export const SplashScreen: React.FC = () => {
  const inputEl = useRef<SgwtSplashScreenHTMLElement>();
 /* useEffect(() => {
    if (inputEl.current && typeof inputEl.current.show !== 'undefined') {
      // we need to setup the environment manually because we cannot put this at a more global level than index.tsx
      // and it is too late for the widget to take the env from the global config
      inputEl.current.widgetConfiguration.environment =
        appConfig.sgConnect.SGWTWidgetConfiguration.environment;
      inputEl.current.application = appConfig.appId;
    }
  }, [inputEl.current]);*/
  return <sgwt-splash-screen ref={inputEl} />;
};
