import { getConfig } from '@/config/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { commonPrepareHeaders } from './apiUtils';

export const transactionApi = createApi({
  reducerPath: 'transactionApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${getConfig()?.api?.endpoint ?? ''}transactions/`,
    prepareHeaders: commonPrepareHeaders,
  }),
  endpoints: (builder) => ({
    getTransactionsForParty: builder.query<
      ListOfTradeInformations,
      {
        mnemonic: string;
        assetClasses: string[];
        tradeDateFrom: string;
        tradeDateTo: string;
      }
    >({
      query: ({ mnemonic, assetClasses, tradeDateFrom, tradeDateTo }) => {
        const destructuredAssetClasses: [string, string][] = assetClasses.map(
          (asset) => ['assetClasses', asset],
        );

        return {
          url: `parties/${mnemonic}`,
          method: 'GET',
          params: [
            ...destructuredAssetClasses,
            ['from', tradeDateFrom],
            ['to', tradeDateTo],
          ],
        };
      },
    }),
  }),
});

export const { useGetTransactionsForPartyQuery } = transactionApi;
